import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import CTABlock from "../../components/blocks/cta_block"

const CoachesPage = ({ data }) => {
  const pageCompliance = {
    __typename: "SimplePage",
    title: "Compliance",
    slug: "compliance",
    live: true,
  }
  // const pageCourses = {
  //   __typename: "CoursesPage",
  //   title: "Courses",
  //   slug: "courses",
  //   live: true,
  // }

  let _ancestors = data.wagtail.page.children
  _ancestors = _ancestors.filter(item => {
    return item.__typename === "SimplePage"
  })

  if (_ancestors.filter(item => item.title === "Compliance").length === 0) {
    _ancestors.splice(3, 0, pageCompliance)
  }

  // if(_ancestors.filter((item) => item.title === "Courses" && item.live).length === 0){
  //   _ancestors.splice(2, 0, pageCourses)
  // }

  return (
    <>
      <Layout
        isContent={true}
        subMenu={_ancestors}
        button={data.wagtail.page.button}
        parent={"coaches"}
        siteTitle={"Coaches"}
      >
        <section className="breadcrumb-area breadcrumb-sub-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb nav-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="simple-page clubs-area main-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1>{data.wagtail.page.title}</h1>
                <p>{data.wagtail.page.descriptionSimple}</p>
                <div className="clib-area">
                  <div className="club-img full-width-image">
                    <img src={data.wagtail.page.feedImage.src} alt="swim" />
                  </div>
                </div>
                <div
                  className="c-title-content"
                  dangerouslySetInnerHTML={{
                    __html: data.wagtail.page.bodySafe,
                  }}
                />
              </div>
            </div>
          </div>
        </section>

        <CTABlock data={data.wagtail.page.fullContent[0]}></CTABlock>
      </Layout>
    </>
  )
}
export const query = graphql`
  {
    wagtail {
      page(id: 3401) {
        ... on CoachesPage {
          title
          descriptionSimple
          body
          bodySafe
          feedImage {
            src
            height
            width
          }
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
          children {
            ... on SimplePage {
              title
              slug
              live
              isProtected
            }
            __typename
          }
          button {
            name
            text
            url
          }
        }
      }
    }
  }
`
export default CoachesPage
